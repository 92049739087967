import React from 'react'
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2'

import pl from 'react-phone-input-2/lang/pl.json'

import { useMarket } from '@/utils/multi-markets/context'
import Error from '@/components/form/Error'

import { FieldLabel, FieldLabelWrapper } from './Field'
import { PhoneField } from './PhoneInput.styled'

export interface PhoneInputValueProps {
  number: string
  format?: string
  countryCode?: string
}

interface Props {
  value: string
  label: string
  placeholder?: string
  error?: string
  hideLabel?: boolean
  onChange(values: PhoneInputValueProps): void
  onBlur?(): void
  dataTestId?: string
}

function PhoneInput(props: Props) {
  const {
    value,
    label,
    error = '',
    placeholder,
    hideLabel = false,
    onChange,
    onBlur,
    dataTestId
  } = props

  const { locale, market } = useMarket()

  const handleChange: PhoneInputProps['onChange'] = (number, data) => {
    const format = ('format' in data && data.format) || ''
    const countryCode = ('countryCode' in data && data.countryCode) || ''

    onChange({ number, format, countryCode })
  }

  return (
    <PhoneField $invalid={!!error}>
      <FieldLabelWrapper>
        <FieldLabel visuallyHidden={hideLabel}>{label}</FieldLabel>
        <ReactPhoneInput
          country={market}
          defaultMask={'... ... ...'}
          masks={{ ae: '.. .......' }}
          inputProps={{ placeholder, 'data-test-id': dataTestId }}
          localization={locale === 'pl' ? pl : undefined}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          enableSearch
          enableLongNumbers // Fix library issue with hard coded max length of 15 digits https://github.com/bl00mber/react-phone-input-2/issues/354
        />
      </FieldLabelWrapper>
      {error && <Error error={error} dataTestId={dataTestId} />}
    </PhoneField>
  )
}

export default PhoneInput
