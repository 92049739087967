import Head from 'next/head'
import { useTranslation } from 'next-i18next'

interface Props {
  title: string
  description?: string
  image?: string
  withoutPostfix?: boolean
  noindex?: boolean
}

function Meta(props: Props) {
  const {
    title,
    description = '',
    image = 'https://cdn.naviparking.com/digital_parking/img/og.webp',
    withoutPostfix = false,
    noindex = false
  } = props

  const { t } = useTranslation('common')

  const postfix = withoutPostfix ? '' : ` — ${t('digitalParking')}`
  const pageTitle = title + postfix

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={pageTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />
      {noindex && <meta name="robots" content="noindex, follow" />}
    </Head>
  )
}

export default Meta
